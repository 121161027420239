<template>
  <div class="navbar-container main-menu-content">
    <!-- Menu Gauche -->
    <horizontal-nav-menu-items :items="navMenuItems" />
    <!-- Logo central -->
    <app-navbar-horizontal-layout-brand />
    <!-- Compte Droite -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- 
        => COMPOSANT UTILISABLE A L'AVENIR
        <search-bar />
        <cart-dropdown />
        <notification-dropdown /> 
        <dark-Toggler class="d-none d-lg-block" />
        <locale />
      -->
      <user-dropdown />
      <horizontal-nav-menu-items :items="navMenuItemsLeft" />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
import store from '@/store'

import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'

import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import UserDropdown from '@/@core/layouts/components/app-navbar/components/UserDropdown.vue'

// => COMPOSANT UTILISABLE A L'AVENIR
// import Bookmarks from '@/@core/layouts/components/app-navbar/components/Bookmarks.vue'
// import navMenuItems from '@/navigation/horizontal'
// import DarkToggler from 'components/DarkToggler.vue'
// import SearchBar from '@/@core/layouts/components/app-navbar/components/SearchBar.vue'
// import CartDropdown from '@/@core/layouts/components/app-navbar/components/CartDropdown.vue'
// import NotificationDropdown from '@/@core/layouts/components/app-navbar/components/NotificationDropdown.vue'

export default {
  components: {
    BLink,
    BNavbarNav,

    HorizontalNavMenuItems,
    AppNavbarHorizontalLayoutBrand,
    
    Locale,
    UserDropdown
  },

  setup () {
    return {}
  },
  mounted(){
    console.log(this.$store)
  },
  computed: {
    navMenuItems () {
      return [
        {
          title: 'Accueil',
          route: 'home',
          icon: 'HomeIcon'
        },
        {
          title: 'Admin',
          icon: 'LockIcon',
          children: [
            {
              title: 'Utilisateurs admin',
              route: 'adminUsers'
            },
            {
              title: 'Workspaces admin',
              route: 'adminWorkspaces'
            }
          ]
        },
        {
          title: 'Affaires',
          route: 'affairs',
          icon: 'BriefcaseIcon'
        },
        {
          title: 'Planning',
          icon: 'CalendarIcon',
          children: [
            {
              type:'divider',
              title: store.getters.planningViewsList.length>1 ? 'Vues de planning' : 'Vue de planning',
              class:'mt-1'
            },
            ...store.getters.planningViewsList.map(e => {
              return {
                title: e.name,
                path: 'planning/' + e.id,
                params: { id: e.id }
              }
            }),
            {
              type:'divider',
              title: 'Paramètres',
              class:'mt-1'
            },
            {
              title: 'Vues de planning',
              route: 'planningViews',
              badge: 'new'
            },
            {
              title: "Types d'évènements",
              route: 'eventTypes'
            },
          ]
        },
        {
          title: 'Annuaire',
          icon: 'BookIcon',
          children: [
            {
              type:'divider',
              title: 'Annuaire',
              class:'mt-1'
            },
            ...store.getters.companiesTypesList.map(e => {
              return {
                title: e.label,
                path: 'directory/companies/' + e.label.toLowerCase() + '/' + e.id,
                params: { name:e.label.toLowerCase(), id: e.id, pageTitle:e.label, title:e.label, tips:'Liste des '+e.label+'s' }
              }
            }),
            // {
            //     type:'divider',
            //     title: '',
            // },
            {
                title: 'Contacts',
                route: 'contacts'
            }
          ]
        },
        {
          title: 'Entreprise',
          icon: 'UsersIcon',
          children: [
            {
              type:'divider',
              title: 'Entreprise',
              class:'mt-1'
            },
            {
              title: 'Collaborateurs',
              route: 'collaborators'
            },
            {
              title: 'Utilisateurs',
              route: 'users'
            }
          ]
        },
        {
          title: 'Paramètres',
          icon: 'SettingsIcon',
          children: [
            {
              title: 'Métiers',
              route: 'jobs'
            },
            {
              title: 'Contrats',
              route: 'contracts'
            },
          ]
        }
      ]
    },
    navMenuItemsLeft () {
      return [
        {
          title: 'Admin',
          icon: 'LockIcon',
          children: [
            {
              title: 'Utilisateurs admin',
              route: 'adminUsers'
            },
            {
              title: 'Workspaces admin',
              route: 'adminWorkspaces'
            }
          ]
        },
        {
          title: 'Paramètres',
          icon: 'SettingsIcon',
          children: [
            {
              title: 'Métiers',
              route: 'jobs'
            },
            {
              title: 'Contrats',
              route: 'contracts'
            },
          ]
        }
      ]
    }
  }
}
</script>
<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/horizontal-menu.scss';
</style>
